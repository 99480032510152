import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import Link from 'next/link'
import React from 'react'
import { UrlObject } from 'url'
import Loader from '../components/Loader'
import { cn, isNewBrandingActive } from './utilities'

// the commented variants are kept here for reference
export const buttonVariants = isNewBrandingActive
  ? cva(
      'appearance-none border-0 hover:no-underline uppercase inline-flex items-center justify-center text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      {
        variants: {
          variant: {
            default: 'bg-primary text-white hover:bg-peach-600',
            light: 'bg-sand-400 text-secondary hover:bg-sand-600',
            yellow: 'bg-yellow-500 text-secondary hover:bg-yellow-600',
            white: 'bg-white text-secondary hover:bg-sand-400',
            danger: 'bg-peach-800 text-white hover:bg-brown',
            dark: 'text-petrol-500 justify-start',
            inline: '!p-0 !h-auto bg-[transparent] radius-none text-primary underline-offset-4 hover:underline',
            ghost: '!bg-[transparent] text-primary',
            muted: 'bg-sand-700 text-white',
          },
          size: {
            default: 'px-8 py-6 text-base rounded-2xl min-width-[220px]',
            medium: 'px-6 py-4 text-sm rounded-xl',
            small: 'px-4 py-3 text-[13px] rounded-xl',
          },
        },
        defaultVariants: {
          variant: 'default',
          size: 'default',
        },
      },
    )
  : cva(
      'border-0 hover:no-underline uppercase inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      {
        variants: {
          variant: {
            default: 'bg-primary text-white hover:bg-brown',
            light: 'bg-bordeaux-background text-text-color-light hover:bg-[#e8ddd2] hover:text-text-color',
            yellow: 'bg-bordeaux-background text-text-color-light hover:bg-[#e8ddd2] hover:text-text-color',
            white: 'bg-white text-text-color hover:bg-[#e8ddd2]',
            danger: 'bg-danger text-white hover:bg-brown',
            dark: 'text-text-color justify-start',
            inline: '!p-0 !h-auto bg-[transparent] radius-none text-primary underline-offset-4 hover:underline',
            ghost: 'bg-transparent text-primary hover:opacity-50 hover:text-white',
            muted: '',

            // destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
            // outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
            // secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
            // ghost: 'hover:bg-accent hover:text-accent-foreground',
            // link: 'text-primary underline-offset-4 hover:underline',
          },
          size: {
            default: 'px-8 py-6 text-base',
            medium: 'px-6 py-4 text-sm',
            small: 'px-4 py-2 text-[13px]',
            // sm: 'h-9 rounded-md px-3',
            // lg: 'h-11 rounded-md px-8',
            // icon: 'h-10 w-10',
          },
        },
        defaultVariants: {
          variant: 'default',
          size: 'default',
        },
      },
    )

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  newWindow?: boolean
}

const RadixButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, loading = false, newWindow = false, disabled, children, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
        type="button"
      >
        {loading ? (
          <>
            {/*
              this could potentially cause problems if used with asChild parameter 
              because it would try to merge the props onto the fragment, which would disregard them
              in such case the problematic result would be immediately visible, so we keep this behavior
            */}
            <Loader visible loaderType="button" />
            <span className="w-full flex-shrink-0">{children}</span>
          </>
        ) : (
          children
        )}
      </Comp>
    )
  },
)
// this is needed due to forwardRef being used
RadixButton.displayName = 'RadixButton'

// this is basically a RadixButton that is accepting href as a prop
// in future we can add all props from next/link to this component
const RadixButtonLink: React.FC<ButtonProps & { href: string | UrlObject }> = ({ href, newWindow, ...props }) => {
  return (
    <RadixButton asChild {...props}>
      <Link href={href || '#'} target={newWindow && '_blank'} rel={newWindow && 'noopener noreferrer'}>
        {props.children}
      </Link>
    </RadixButton>
  )
}

export { RadixButton, RadixButtonLink }
